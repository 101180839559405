:root{
    /* background-color: #1c1917; */
    background-color: #0F172A;
}

/* width */
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px gray; 
    border-radius: 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #94a3b8;
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #fb923c;
    cursor: pointer;
}

input, select {
    background: transparent;
}
option{
    background-color: #0F172A;
    color: white;
}