@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .text-input {
      @apply w-full mt-1 px-3 py-2 border border-slate-300 placeholder-slate-500 text-slate-900 rounded-md focus:outline-none focus:ring-orange-400 focus:border-orange-400
  }
  .label-form{
      @apply block mb-2 text-sm font-medium text-slate-300
  }
  .input-form{
      @apply block w-full p-2.5 text-sm rounded-lg bg-slate-600 border border-slate-500 focus:ring-orange-400 focus:border-orange-400 placeholder-slate-400 text-white
  }
  .btn-submit {
      @apply w-full flex justify-center py-2 px-4 border border-transparent font-medium rounded-md text-white bg-orange-400 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-400
  }
  .btn-primary{
      @apply text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-orange-400 hover:bg-orange-500 focus:ring-4 focus:ring-orange-400
  }
  .btn-secondary{
      @apply rounded-lg text-sm font-medium px-5 py-2.5 focus:z-10 bg-slate-700 hover:bg-slate-600 text-slate-300 hover:text-white border border-slate-500 focus:ring-4 focus:ring-slate-300
  }
  .btn-danger{
      @apply text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5
  }
  .modal-form{
      @apply fixed w-full h-full top-0 left-0 flex items-center justify-center z-full
  }
  .modal-form-outside{
      @apply absolute w-full h-full bg-slate-900 opacity-50
  }
  .btn-table-action{
    @apply bg-slate-800 text-white hover:bg-orange-400 text-base w-8 h-8 flex justify-center items-center space-x-1 rounded-full shadow
  }
    .label-setting{
      @apply block mb-2 text-sm cursor-pointer font-medium
    }
    .input-setting{
      @apply block w-full p-2.5 lg:px-3 text-sm rounded-lg bg-slate-100 focus:outline-none border border-slate-200 focus:border-orange-400 focus:ring-orange-400 focus:ring-1 placeholder-slate-400 text-slate-600 font-medium placeholder:font-normal
    }
}


  .multiselectContainer { 
      /* background-color: aqua; */
      /* min-height: 500px; */
  }
  .searchBox {
	border: none;
    color: white;
    /* min-height: 100px; */
  }
  .inputField {
      /* border: none; */
      /* margin: 5px; */
      /* background-color: aqua; */
      /* color: brown; */
  }
  .chips {
	/* background: red; */
  }
  .optionContainer {
      /* overflow: hidden; */
      /* height: 500px; */
      width: 100%;
      position: absolute;
    /* min-height: 400px; */
    /* max-height: 500px; */
    background-color: #1e293b;
	color: #fff;
  }
  .option {
	/* color: #0F172A; */
  }
  .groupHeading {
  }


  /* Preventing an image from being draggable or selectable in HTML */
  body {
    -drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
 }

 img {
  -drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}